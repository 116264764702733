@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  transition: background 1s;
}

div, img, a, p, hr {
  transition: height 1s, width 1s, padding 1s, margin 1s, top 1s, bottom 1s, left 1s, right 1s, background 1s, opacity 1s;
}

.no-transition {
transition: none !important;
}
